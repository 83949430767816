.loader {
	width: 10;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.loader {
		animation: loader-spin infinite 4s linear;
	}
}

@keyframes loader-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
