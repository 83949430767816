@font-face {
	font-family: "Futura-Bold";
	src: local("Futura-Bold"), url(./fonts/Futura-Bold.ttf) format("truetype");
}

@font-face {
	font-family: "Futura-Medium";
	src: local("Futura-Medium"), url(./fonts/Futura-Medium.ttf) format("truetype");
}

@font-face {
	font-family: "Futura-CondensedExtraBold";
	src: local("Futura-Condensed-Extra-Bold"),
		url(./fonts/Futura-Condensed-Extra-Bold.ttf) format("truetype");
}

@font-face {
	font-family: "Futura-MediumItalic";
	src: local("Futura-Medium-Italic-Regular"),
		url(./fonts/Futura-Medium-Italic-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "Futura-CondensedMedium";
	src: local("Futura-Medium-Condensed"),
		url(./fonts/Futura-Medium-Condensed.ttf) format("truetype");
}

/* css for rheostat component */
.rheostat {
	position: relative;
	overflow: visible;
	height: 50px;
}
.rheostat_background {
	background-color: #fcfcfc;
	border: 1px solid #d8d8d8;
	position: relative;
}
/* .rheostat_background__vertical {
	width: 15px;
	top: 0px;
	height: 100%;
} */
.DefaultProgressBar_progressBar {
	background-color: #000;
	position: absolute;
}
.DefaultProgressBar_background__horizontal {
	height: 3px;
	top: 7px;
}
.DefaultBackground {
	background-color: #f6f4f4;
	height: 3px;
	width: 100%;
	position: relative;
}
.DefaultBackground_background__horizontal {
	top: 7px;
	left: -2px;
	bottom: 4px;
}
@media (min-width: 1128px) {
	.autoAdjustVerticalPosition {
		top: 12px;
	}
}
.handleContainer {
	height: 15px;
	top: -2px;
	left: -2px;
	bottom: 4px;
	width: 100%;
	position: absolute;
}
.DefaultHandle_handle {
	width: 20px;
	height: 20px;
	border-width: 2px;
	border-style: solid;
	border-color: #000;
	background-color: #fcfcfc;
	border-radius: 100px;
	outline: none;
	z-index: 2;
	box-shadow: 0 2px 2px #dbdbdb;
	cursor: pointer;
}
.DefaultHandle_handle:focus {
	box-shadow: #abc4e8 0 0 1px 1px;
}
.DefaultHandle_handle__horizontal {
	margin-left: -12px;
}
.DefaultHandle_handle__disabled {
	border-color: #dbdbdb;
}
.form-control:focus {
	box-shadow: none;
	background-color: none;
}
